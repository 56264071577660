
import { defineComponent, onBeforeUnmount, reactive, ref, watch } from 'vue'
import useAreaCode from '@/hooks/useAreaCode'
import usePages from '@/hooks/usePages'
import useSendSms from '@/hooks/useSendSms'
import useLogin from '@/hooks/useLogin'
import { Toast } from 'vant'

export default defineComponent({
  setup () {
    const { areaCode, codeOptions } = useAreaCode()
    const phoneData = reactive({
      areaCode,
      phone: '',
      sms: '',
      agree: false
    })
    const { toProtocol, toPrivacy, toDisclaimer, toPrivacy1, toElec } = usePages()
    const { handleClickSend, smsData, validatePhone } = useSendSms(phoneData)
    const { login, checkInput, btnLoading } = useLogin(phoneData)
    const confirmCode = (val: string) => {
      phoneData.areaCode = val
      show.value = false
    }
    const show = ref(false)
    const defaultPhoneHeight = window.innerHeight
    const nowPhoneHeight = ref(0)
    const hideAgree = ref(true)
    window.onresize = () => {
      nowPhoneHeight.value = window.innerHeight
    }
    watch(nowPhoneHeight, () => {
      if (nowPhoneHeight.value / defaultPhoneHeight < 0.8) {
        hideAgree.value = false
      } else {
        hideAgree.value = true
      }
    })

    const handleSendSms = () => {
      if (phoneData.phone) {
        handleClickSend()
      } else {
        Toast('请输入手机号')
      }
    }
    onBeforeUnmount(() => {
      window.onresize = null
    })
    return {
      btnLoading,
      areaCode,
      phoneData,
      hideAgree,
      show,
      codeOptions,
      smsData,
      login,
      checkInput,
      handleSendSms,
      handleClickSend,
      confirmCode,
      validatePhone,
      toProtocol,
      toPrivacy,
      toDisclaimer,
      toPrivacy1,
      toElec
    }
  }
})

