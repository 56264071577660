import { PHONE } from '@/config/index'
import { useMutation } from '@vue/apollo-composable'
import { Toast } from 'vant'
import { reactive, toRefs } from 'vue'
const ghLogin = require('@/graphql/mutation/ghLogin.gql')

interface Func {
  smsData: {
    btnTxt: string,
    seconds: number,
    codeDisabled: boolean
  }
  validatePhone: (val:string) => void
  handleClickSend: () => void
}

interface PhoneInfo {
  areaCode: string
  phone: string,
}
export default function userSendSms (info: PhoneInfo): Func {
  const { areaCode, phone } = toRefs(info)
  const smsData = reactive({
    btnTxt: '获取验证码',
    seconds: 60,
    codeDisabled: false
  })
  const { mutate, onDone } = useMutation(ghLogin)

  let timer: number | undefined
  const countDown = () => {
    smsData.seconds -= 1
    if (smsData.seconds < 0) {
      clearInterval(timer)
      smsData.codeDisabled = false
      smsData.seconds = 60
    }
  }

  const validatePhone = (val: string) => {
    if (!PHONE.test(val)) {
      Toast('手机号格式不正确')
      return false
    }
    return true
  }
  // 点击发送验证码触发防刷机制
  const handleClickSend = () => {
    const captcha = new window.TencentCaptcha(process.env.VUE_APP_H5_TENCENT, (res: { ret: number, ticket: string, randstr: string }) => {
      console.log('res', res)
      if (res.ret === 0) {
        const str = `${res.ticket},${res.randstr}`
        sendCode(str)
      }
    })
    captcha.show()
  }
  const sendCode = (code: string) => {
    console.log('点击发送', phone)
    if (!phone) {
      Toast('请输入手机号')
      return
    }
    if (!validatePhone(phone.value)) {
      Toast('手机号格式不正确')
      return
    }
    if (smsData.codeDisabled) {
      return
    }
    smsData.codeDisabled = true
    smsData.btnTxt = '重新获取'
    if (smsData.seconds === 60) {
      const param = {
        areaCode: areaCode.value,
        phone: phone.value,
        code,
        codeType: 'sendPhoneLoginSms'
      }
      mutate({ param })
      onDone(result => {
        console.log(result.data)
        const { ghLogin } = result.data
        if (ghLogin.reason?.err === 35114) {
          Toast('验证码发送成功')
        } else {
          Toast(ghLogin.reason.desc)
        }
      })
    }
    timer = setInterval(countDown, 1000)
  }
  return {
    validatePhone,
    smsData,
    handleClickSend
  }
}
