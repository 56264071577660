
import { useMutation } from '@vue/apollo-composable'
import { ref } from 'vue'

const getAreaCodes = require('@/graphql/mutation/getAreaCodes.gql')

// 获取数据字典
const useAreaCode = ():any => {
  // 查询区号
  const { mutate: getAreaCodesFunc, onDone: getAreaCodesDone } = useMutation(
    getAreaCodes
  )

  getAreaCodesFunc({
    params: {
      areaType: '',
      loginIp: ''
    }
  })
  const areaCode = ref('86')
  const codeOptions = ref()

  getAreaCodesDone(res => {
    codeOptions.value = res.data.getAreaCodes.data.options
    areaCode.value = res.data.getAreaCodes.data.default.areaCode
  })

  return {
    areaCode,
    codeOptions
  }
}

export default useAreaCode
