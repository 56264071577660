import { useMutation } from '@vue/apollo-composable'
import { Toast } from 'vant'
import { Ref, ref, toRefs } from 'vue'
import * as storage from '@/utils/storage'
import { base64Encrypt } from '@/utils/crypto'
import qs from 'qs'
import { getURLParameters, objParseQuery } from '@/utils/url'

const ghLogin = require('@/graphql/mutation/ghLogin.gql')

interface DataInfo {
  areaCode: string
  phone: string
  sms: string
  agree: boolean
}

interface Func {
  btnLoading: Ref<boolean>
  checkInput: () => boolean
  login: () => void
}

export default function useLogin (data: DataInfo): Func {
  const { areaCode, phone, sms, agree } = toRefs(data)
  const { mutate, onDone } = useMutation(ghLogin)
  const btnLoading = ref(false)
  const checkInput = () => {
    if (!phone.value) {
      Toast('请输入手机号')
      return false
    }
    if (!sms.value) {
      Toast('请输入短信验证码')
      return false
    }
    if (!agree.value) {
      Toast('请阅读并同意个人资料收集声明,网站使用条款,免责声明,私隐政策及电子交易条款')
      return false
    }
    return true
  }

  const login = () => {
    const status = checkInput()
    if (status) {
      btnLoading.value = true
      mutate({
        param: {
          areaCode: areaCode.value,
          phone: phone.value,
          code: sms.value,
          codeType: 'loginAutoRegisterSms'
        }
      })
    }
  }

  const redirectFunc = <T>(data: T) => {
    const param = {
      ...data,
      deviceId: storage.get('deviceId'),
      userInfo: {
        phone: phone.value,
        areaCode: areaCode.value
      }
    }
    const userInfo = base64Encrypt(JSON.stringify(param))
    const { search } = window.location
    const { redirect_url: redirectUrl = '' } = qs.parse(search, {
      ignoreQueryPrefix: true
    })
    const rUrl = decodeURIComponent(redirectUrl as string)
    const redirectObj = getURLParameters(rUrl)
    const redirectHash = rUrl.split('#')
    const obj = Object.assign({}, redirectObj, { userInfo })
    const query = objParseQuery(obj)
    const backUrlHref = `${redirectHash[0].split('?')[0]}?${query}`
    window.location.href = redirectHash[1]
      ? backUrlHref + '#' + redirectHash[1]
      : backUrlHref
  }

  onDone(result => {
    const { ghLogin } = result.data
    if (ghLogin.ok) {
      Toast('登录成功')
      redirectFunc(ghLogin.data)
      btnLoading.value = false
    } else {
      Toast(ghLogin.reason.desc)
      btnLoading.value = false
    }
  })
  return {
    login,
    checkInput,
    btnLoading
  }
}
