/**
 * Usage:  将一个对象转换为url的get参数
 */
export const objParseQuery = (obj: { [x: string]: string | number | boolean }):string => {
  if (typeof obj !== 'object') return ''
  const arr = []
  for (const key in obj) {
    arr.push(key + '=' + encodeURIComponent(obj[key]))
  }
  return arr.join('&')
}

/**
 * Usage: 返回一个包含当前 URL 参数的对象。
 * “使用match()与适当的正则表达式来获取所有键值对, Array.reduce()可将它们映射并合并到单个对象中。将location.search作为要应用于当前url的参数传递.”
 * Example: getURLParameters('http://url.com/page?name=Adam&surname=Smith') -> {name: 'Adam', surname: 'Smith'}
 */
export const getURLParameters = (url = window.location.href):unknown => {
  const params = url.match(/([^?=&]+)(=([^(&|#|/)]*))/g)
  if (!params) {
    return {}
  } else {
    return params.reduce(
      (a:any, v: string) => {
        a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)
        return a
      }, {}
    )
  }
}
