interface Pages {
  toProtocol: () => void
  toPrivacy: () => void
  toDisclaimer: () => void
  toPrivacy1: () => void
  toElec: () => void
}
export default function usePages (): Pages {
  const toProtocol = () => {
    window.location.href =
      process.env.VUE_APP_H5_STATIC_PAGE_URL + 'login_person_info.html'
  }
  const toPrivacy = () => {
    window.location.href =
      process.env.VUE_APP_H5_STATIC_PAGE_URL + 'terms_of_use.html'
  }
  const toDisclaimer = () => {
    window.location.href =
      process.env.VUE_APP_H5_STATIC_PAGE_URL + 'login_disclaimer.html'
  }
  const toPrivacy1 = () => {
    window.location.href =
      process.env.VUE_APP_H5_STATIC_PAGE_URL + 'login_privacy.html'
  }
  const toElec = () => {
    window.location.href =
      process.env.VUE_APP_H5_STATIC_PAGE_URL + 'electronic_transaction.html'
  }
  return {
    toProtocol,
    toPrivacy,
    toDisclaimer,
    toPrivacy1,
    toElec
  }
}
